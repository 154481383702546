import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

const About = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <section className="blockback">
          <MDBContainer>
            <div className="blockquote-wrapper">
              <div className="blockquote">
              <div className="strapline">{post.frontmatter.strapline}</div>
                  <div className="author">{post.frontmatter.author}</div>
              </div>
            </div>
          </MDBContainer>
        </section>
      </main>
    </Layout>
  )
}
export default About 

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "about" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        strapline
        author
        image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
        alttext
        section {
          title
          subtitle
          description
          image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          placement
          alttext
          link
        }
      }
      html
    }
  }
`
